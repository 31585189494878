import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Modal, Button, LoadingButton, Input, RemoveModal, Select } from '@forma/forma-ui-kit';
import UserIcon from '../UserIcon';
import PrioritySelect from '../PrioritySelect';

import { selectUserData } from 'store/user/userSlice';
import { useAddTaskCommentMutation, useGetTaskByIdQuery, useGetTaskFileMutation, useRemoveTaskMutation, useUpdateTaskMutation } from 'store/tasks/tasksApi';
import { useGetTokenMutation } from 'store/workspaces/workspacesApi';

import styles from './task-edit-modal.module.css';

const siteUrl = process.env.REACT_APP_LK_URL;

const UserOptionLabel = ({ name, login, color, isSelf }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.option}>
      <UserIcon name={name} color={color} />
      <div>
        <div>{name || login}</div>
        {isSelf && <div className={styles.forSelf}>{t('assign_for_yourself')}</div>}
      </div>
    </div>
  );
};

const TaskEditModal = ({ open, onClose, positions, admins, id }) => {
  const { t } = useTranslation();
  const [ comment, setComment ] = useState('');
  const user = useSelector(selectUserData);
  const { data: task } = useGetTaskByIdQuery(id, { skip: !open });
  const [ addComment, { isLoading: isAddCommentLoading } ] = useAddTaskCommentMutation();
  const [ updateTask ] = useUpdateTaskMutation();
  const [ removeTask ] = useRemoveTaskMutation();
  const [ getToken ] = useGetTokenMutation();
  const [ downloadFile, { isLoading: isDownloadLoading } ] = useGetTaskFileMutation();

  const handleClickDownload = () => {
    downloadFile(id).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = task.filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(e => {
      // eslint-disable-next-line
      console.error(e);
    });
  };

  const handleSaveComment = (e) => {
    addComment({ id, message: comment });
    setComment('');
  };

  const handleChangeManager = (e) => {
    updateTask({ id, managerlogin: e.target.value });
  };

  const handleChangePosition = (e) => {
    updateTask({ id, position: e.target.value });
  };

  const handleChangePriority = (e) => {
    updateTask({ id, priority: e.target.value });
  };

  const handleClickSignAs = () => {
    if (!task?.creator?.workspace?.id) return null;
    getToken({ wid: task.creator.workspace.id }).then(result => {
      if (result.data?.token) window.open(`${siteUrl}/auth?token=${result.data.token}`, '_blank');
    });
  };

  const handleClickRemove = () => {
    removeTask(id);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="large"
    >
      <div className={styles.head}>
        <div className={styles.creator}>
          {task?.type === 'SHAREDTEMPLATE' ? (
            <>
              {t('forma_template')}
              <img src="/images/small_logo.svg" className={styles.titleIcon} width={26} height={26} alt={t('forma_template')} />
            </>
          ) : (
            <>
              {task?.creator.login}
              <Button
                as={Link}
                viewStyle="textLight"
                icon={<ReactSVG src="/icons/user.svg" />}
                title={t('go_to_usercard')}
                to={`/workspaces/${task?.creator?.workspace.id}`}
              />
            </>
          )}
        </div>
        <div className={classNames(styles.time, styles[task?.durationType])}>
          {task?.duration}
        </div>
        {task?.creator?.id && (
          <Button
            size="extraSmall"
            viewStyle="primary"
            className={styles.signInButton}
            onClick={handleClickSignAs}
          >
            {t('sign_in_account')}
          </Button>
        )}
        <RemoveModal
          control={
            <Button
              viewStyle="textLight"
              className={styles.remove}
              icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
            />
          }
          title={t('deleting_task')}
          message={<>{t('do_yo_want_delete_task')}?</>}
          onRemove={handleClickRemove}
        />
      </div>
      <div className={styles.head}>
        <div className={styles.fileName}>
          {task?.filename}
        </div>
        {task?.filename && (
          <LoadingButton
            viewStyle="textPrimary"
            className={styles.downloadButton}
            iconClassName={styles.downloadButtonIcon}
            onClick={handleClickDownload}
            isLoading={isDownloadLoading}
          >
            {t('download')}
          </LoadingButton>
        )}
      </div>
      <div className={styles.head}>
        <Select
          className={styles.select}
          options={admins?.map(({ name, login, color }) => (
            {
              label: <UserOptionLabel name={name} login={login} color={color} isSelf={user?.login === login} />,
              value: login,
              displayLabel: <>{t('executor')}: {name || login}</>
            }
          )) ?? []}
          placeholder={t('select_executor')}
          value={task?.manager.login}
          onChange={handleChangeManager}
          meta={{
            containerClass: classNames(styles.selectContainer, styles.executor),
            customLabelKey: 'displayLabel'
          }}
        />
        <PrioritySelect
          containerClass={classNames(styles.selectContainer, styles.priority)}
          className={styles.select}
          onChange={handleChangePriority}
          value={String(task?.priority)}
        />
        <Select
          className={styles.select}
          options={positions.map(status => ({ label: t(`task_types.${status.toLowerCase()}`), value: status }))}
          value={task?.position}
          onChange={handleChangePosition}
          meta={{
            containerClass: classNames(styles.selectContainer, styles.position)
          }}
        />
      </div>
      <div className={styles.history}>
        <div className={styles.historyHead}>
          <Input
            id={`task_comment_${id}`}
            name="task_comment"
            onChange={e => setComment(e.target.value)}
            className={styles.comment}
            placeholder={t('add_comment')}
            value={comment}
            maxLength={250}
            autoComplete="off"
          />
          <LoadingButton
            viewStyle="primary"
            className={styles.sendButton}
            onClick={handleSaveComment}
            disabled={comment.length < 3}
            isLoading={isAddCommentLoading}
          >
            {t('send')}
          </LoadingButton>
        </div>
        <div className={styles.historyList}>
          {task?.logs?.length ? (
            task.logs.map(({ createdAt, type, message, user }) => (
              <div className={classNames(styles.historyItem, type === 'COMMENT' && styles.asComment)} key={createdAt}>
                {format(new Date(createdAt), 'dd.MM.yyyy (HH:mm мск)')} {user.name && `от ${user.name}`}: {message}
              </div>
            ))
          ) : (
            <div className={styles.historyEmpty}>
              not found
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TaskEditModal;
