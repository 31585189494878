import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Select } from '@forma/forma-ui-kit';
import SearchInput from 'components/SearchInput';

import styles from './workspaces-list-header.module.css';

const WorkspacesListHeader = ({ tariffs, featured, onChangeSearch, onChangeFilter }) => {
  const { t } = useTranslation();
  const activeOptions = [
    { label: t('all_users'), value: '' },
    { label: t('actives'), value: true },
    { label: t('not_actives'), value: false }
  ];
  let tariffsOptions = [];
  if (tariffs) tariffsOptions = [ ...tariffsOptions, ...tariffs.map(({ id, name }) => ({ label: name, value: id })) ];

  const durationOptions = [
    { label: t('due_date'), value: '' },
    { label: '1 мес', value: '30' },
    { label: '3 мес', value: '90' },
    { label: '6 мес', value: '120' },
    { label: '12 мес', value: '360' },
  ];

  const countOptions = [
    { label: t('count_users'), value: '' },
    { label: t('one_user'), value: '1' },
    { label: t('from_to', { from: 1, to: 5 }), value: '1-5' },
    { label: t('from_to', { from: 5, to: 10 }), value: '5-10' },
    { label: t('from_to', { from: 10, to: 15 }), value: '10-15' },
    { label: t('from_to', { from: 15, to: 20 }), value: '15-20' },
    { label: t('from_to', { from: 20, to: 30 }), value: '20-30' },
    { label: t('from_to', { from: 30, to: 50 }), value: '30-50' },
    { label: t('from_to', { from: 50, to: 100 }), value: '50-100' },
    { label: t('large', { count: 100 }), value: '100' },
  ];

  const promoOptions = [
    { label: t('all_users'), value: '', displayLabel: <ReactSVG src="/icons/password.svg" className={styles.selectButtonIcon} wrapper="span" /> },
    { label: t('with_promo'), value: 'true', displayLabel: <ReactSVG src="/icons/password.svg" className={styles.selectButtonIcon} wrapper="span" /> },
    { label: t('without_promo'), value: 'false', displayLabel: <ReactSVG src="/icons/password.svg" className={styles.selectButtonIcon} wrapper="span" /> },
  ];

  const dueOptions = [
    { label: t('all'), value: '', displayLabel: <ReactSVG src="/icons/calendar.svg" className={styles.selectButtonIcon} wrapper="span" /> },
    { label: t('overdue'), value: 'O', displayLabel: <ReactSVG src="/icons/calendar.svg" className={styles.selectButtonIcon} wrapper="span" /> },
    { label: t('less_week'), value: 'W', displayLabel: <ReactSVG src="/icons/calendar.svg" className={styles.selectButtonIcon} wrapper="span" /> },
    { label: t('less_two_weeks'), value: 'T', displayLabel: <ReactSVG src="/icons/calendar.svg" className={styles.selectButtonIcon} wrapper="span" /> },
    { label: t('less_month'), value: 'M', displayLabel: <ReactSVG src="/icons/calendar.svg" className={styles.selectButtonIcon} wrapper="span" /> },
  ];

  const handleChangeUsers = (e) => {
    const counts = e.target.value.split('-');
    onChangeFilter({ currentUsersCountFrom: counts[0] });
    onChangeFilter({ currentUsersCountTo: counts[1] });
  };

  const handleChangeEnd = (e) => {
    if (e.target.value === 'O') onChangeFilter({ daysLeftFrom: 0, daysLeftTo: 0 });
    else if (e.target.value === 'W') onChangeFilter({ daysLeftFrom: 0, daysLeftTo: 7 });
    else if (e.target.value === 'T') onChangeFilter({ daysLeftFrom: 0, daysLeftTo: 14 });
    else if (e.target.value === 'M') onChangeFilter({ daysLeftFrom: 0, daysLeftTo: 30 });
    else onChangeFilter({ daysLeftFrom: undefined, daysLeftTo: undefined });
  };

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <SearchInput
          onSearch={onChangeSearch}
          className={styles.search}
          placeholder={t('email_or_inn')}
        />
        <div>
          <Button
            viewStyle="secondary"
            icon={<ReactSVG src="/icons/favorite.svg" wrapper="span" className={classNames(styles.icon, styles.active)} />}
            onClick={() => onChangeFilter({ featured: !featured })}
            shadow
          >
            {t('favourites')}
          </Button>
        </div>
        <Select
          name="promocode"
          defaultValue={promoOptions[0].value}
          options={promoOptions}
          onChange={e => onChangeFilter({ promocode: e.target.value || undefined })}
          meta={{
            containerClass: styles.selectSmall,
            customLabelKey: 'displayLabel',
            dropdownWidth: 200
          }}
        />
        <Select
          name="tariff_end"
          defaultValue={dueOptions[0].value}
          options={dueOptions}
          onChange={handleChangeEnd}
          meta={{
            containerClass: styles.selectSmall,
            customLabelKey: 'displayLabel',
            dropdownWidth: 200
          }}
        />
      </div>
      <div className={styles.row}>
        <Select
          name="active"
          defaultValue={activeOptions[0]}
          options={activeOptions}
          onChange={e => onChangeFilter({ isActive: e.target.value || undefined })}
          meta={{
            containerClass: styles.select,
          }}
        />
        <Select
          name="users"
          defaultValue={countOptions[0]}
          options={countOptions}
          onChange={handleChangeUsers}
          meta={{
            containerClass: styles.select,
          }}
        />
        <Select
          name="tariff"
          defaultValue={tariffsOptions[0] ? [tariffsOptions[0]] : []}
          options={tariffsOptions}
          onChange={e => onChangeFilter({ tariff: e.target.value.length ? e.target.value.split(',') : undefined })}
          placeholder={t('all_tariffs')}
          multiple
          meta={{
            containerClass: styles.select,
          }}
        />
        <Select
          name="duration"
          defaultValue={durationOptions[0]}
          options={durationOptions}
          onChange={e => onChangeFilter({ duration: e.target.value || undefined })}
          meta={{
            containerClass: styles.select,
          }}
        />
      </div>
    </div>
  );
};

export default WorkspacesListHeader;