import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import DashboardPayments from 'components/DashboardPayments';
import { useGetAnalyticsPaymentsQuery } from 'store/analytics/analyticsApi';

const Payments = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetAnalyticsPaymentsQuery();

  return (
    <AdminLayout
      title="Количество оплат"
      breadcrumbs={[
        { name: t('dashboard'), to: '/', as: Link },
        { name: 'Количество оплат' }
      ]}
    >
      <PageTitle>{t('site_name') + ' – Количество оплат'}</PageTitle>
      <DashboardPayments isLoading={isLoading} {...data} />
    </AdminLayout>
  );
};

export default Payments;
