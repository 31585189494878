import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import VariableEdit from 'components/variables/VariableEdit';

import {
  useGetStrictVariableByIdQuery,
  useUpdateStrictVariableMutation,
  useGetStrictSectionsQuery,
  useGetStrictFoldersQuery
} from 'store/variables/variablesApi';
import { useGetTemplateVariablesTypesQuery } from 'store/schemas/schemasApi';
import { setNotification } from 'store/notifications/notificationsSlice';

const Variables = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { data: variable } = useGetStrictVariableByIdQuery(id);
  const { data: sections } = useGetStrictSectionsQuery();
  const { data: folders } = useGetStrictFoldersQuery();
  const { data: variblesTypes } = useGetTemplateVariablesTypesQuery();
  const [ updateVariable ] = useUpdateStrictVariableMutation();

  const handleSaveVariable = async (values) => {
    const data = { ...values };
    delete(data.lang);
    const res = await updateVariable(data);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
    }
  };

  return (
    <AdminLayout title={t('variables')}>
      <PageTitle>{t('site_name') + ' – ' + t('variables')}</PageTitle>
      {(variable && sections && folders && variblesTypes) && (
        <VariableEdit
          variblesTypes={variblesTypes}
          onSave={handleSaveVariable}
          data={variable}
          sections={sections}
          folders={folders}
        />
      )}
    </AdminLayout>
  );
};

export default Variables;