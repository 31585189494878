import { ITemplateBlocksTree, ITemplateRenderData, ITemplateSide } from '@forma/forma-ui-kit';
import { baseApi } from 'store/store';
import {
  // IStrictRenderItem,
  IStrictTemplateItem,
} from '../model/types';

export const strictTemplatesApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['StrictTemplates', 'StrictRenders'] })
  .injectEndpoints({
    endpoints: builder => ({
      getStrictTemplates: builder.query<IStrictTemplateItem[], void>({
        query: () => ({
          url: '/stricttemplates/',
          method: 'GET',
        }),
        providesTags: ['StrictTemplates']
      }),
      getStrictTemplateById: builder.query<IStrictTemplateItem, string>({
        query: (id) => ({
          url: `/stricttemplates/${id}`,
          method: 'GET',
        }),
        providesTags: (result, error, id) => [{ type: 'StrictTemplates', id }],
      }),
      getStrictTemplateSides: builder.query<{ sides: ITemplateSide[], blocksTree?: ITemplateBlocksTree}, string>({
        query: (id) => ({
          url: `/stricttemplates/${id}/sides`,
          method: 'GET',
        }),
        providesTags: (result, error, id) => [{ type: 'StrictTemplates', id, name: 'sides' }],
      }),
      renderStrictTemplate: builder.mutation<string, ITemplateRenderData>({
        query: ({ id, ...data }) => ({
          url: `/stricttemplates/${id}/render`,
          method: 'POST',
          body: data,
          responseHandler: async (response: Response) => {
            if (!response.ok) return response.text().then(text => JSON.parse(text));
            return response.blob().then(blob => URL.createObjectURL(blob));
          },
        }),
        invalidatesTags: ['StrictTemplates']
      }),
      downloadRenderedStrictTemplate: builder.query<string, { id: string, renderId: string }>({
        query: ({ id, renderId }) => ({
          url: `/stricttemplates/${id}/render/${renderId}`,
          method: 'GET',
          responseHandler: async (response: Response) => {
            if (!response.ok) return response.text().then(text => JSON.parse(text));
            return response.blob().then(blob => URL.createObjectURL(blob));
          },
        }),
        providesTags: (result, error, { id, renderId }) => [{ type: 'StrictTemplates', id, renderId }],
      }),

      addStrictTemplate: builder.mutation<{ id: string }, { name: string, sides: ITemplateSide[], blocksTree: ITemplateBlocksTree }>({
        query: (data) => ({
          url: `/stricttemplatesadmin/`,
          method: 'POST',
          body: data
        }),
        invalidatesTags: ['StrictTemplates']
      }),
      updateStrictTemplate: builder.mutation<{ id: string }, { id: string, name: string, sides: ITemplateSide[], blocksTree: ITemplateBlocksTree }>({
        query: ({ id, ...data }) => ({
          url: `/stricttemplatesadmin/${id}`,
          method: 'PUT',
          body: data
        }),
        invalidatesTags: ['StrictTemplates']
      }),
      uploadStrictTemplate: builder.mutation<{ id: string }, { id: string, file: File }>({
        query: ({ id, file }) => {
          const formData = new FormData();
          formData.append('file', file);
          return ({
            url: `/stricttemplatesadmin/${id}/upload`,
            method: 'POST',
            body: formData
          })
        },
        invalidatesTags: (result, error, data) => [{ type: 'StrictTemplates', id: data.id }]
      }),
      removeStrictTemplate: builder.mutation<{ id: string }, string>({
        query: id => ({
          url: `/stricttemplatesadmin/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['StrictTemplates']
      }),

      // getStrictRenders: builder.query<IStrictRenderItem[], void>({
      //   query: () => ({
      //     url: '/strictrenders/',
      //     method: 'GET',
      //   }),
      //   providesTags: ['StrictRenders']
      // }),
      // getStrictRenderById: builder.query<IStrictRenderItem, string>({
      //   query: (id) => ({
      //     url: `/strictrenders/${id}`,
      //     method: 'GET',
      //   }),
      //   providesTags: (result, error, id) => [{ type: 'StrictRenders', id }],
      // }),
      // removeStrictDocument: builder.mutation<{ id: string }, string>({
      //   query: id => ({
      //     url: `/strictrenders/${id}`,
      //     method: 'DELETE',
      //   }),
      //   invalidatesTags: ['StrictTemplates']
      // }),
    })
  });

export const {
  useGetStrictTemplatesQuery,
  useGetStrictTemplateByIdQuery,
  useGetStrictTemplateSidesQuery,
  useRenderStrictTemplateMutation,
  useLazyDownloadRenderedStrictTemplateQuery,

  useAddStrictTemplateMutation,
  useUpdateStrictTemplateMutation,
  useUploadStrictTemplateMutation,
  useRemoveStrictTemplateMutation,

  // useGetStrictRendersQuery,
  // useGetStrictRenderByIdQuery,
  // useRemoveStrictDocumentMutation
} = strictTemplatesApi;