import { createApi } from '@reduxjs/toolkit/query/react';
import { configureStore } from '@reduxjs/toolkit';
import baseQuery from './baseQuery';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import commonReducer from './common/commonSlice';
import notificationsReducer from './notifications/notificationsSlice';

export const baseApi = createApi({
  baseQuery: baseQuery,
  tagTypes: [
    'Resources', 'EditorStyles', 'Counteragents',
    'Tariffs', 'TariffsHistory', 'Promocodes', 'Requests',
    'Timeslots', 'Managers', 'ManagerSlot',
    'Admin', 'User', 'UserPermissions', 'Users', 'UserGroup', 'Groups',
    'Variables', 'VariablesUsed', 'Sections', 'Folders',
    'Workspaces', 'WorkspacesFavourites', 'ScoringList', 'ExternalInfillEntities',
    'Schemas'
  ],
  endpoints: () => ({}),
});

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    auth: authReducer,
    user: userReducer,
    common: commonReducer,
    notifications: notificationsReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([
    baseApi.middleware
  ])
});