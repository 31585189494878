import { Tooltip } from '@forma/forma-ui-kit';
import { formatPrice } from 'helpers/formatValues';
import { currencies } from 'data/mock';
import DashboardBlock from '../DashboardBlock';

import styles from './dashboard-ltv.module.css';
import { useTranslation } from 'react-i18next';

const DashboardLTV = ({ amount, duration }) => {
  const { t } = useTranslation();
  const months = Number((duration / 30).toFixed(0));

  return (
    <DashboardBlock
      title="LTV"
      buttons={
        <Tooltip
          control={<img src="/icons/question-circle.svg" alt="" />}
        >
          <div style={{ width: '226px' }}>
            Сколько один воркспейс в среднем приносит оплат (считаем только среди оплаченных) и через какое время перестает пользоваться
          </div>
        </Tooltip>
      }
    >
      <div className={styles.root}>
        <div className={styles.value}>
          {formatPrice(amount, currencies.ru)} <span className={styles.valueText}>Приносит за жизнь клиент</span>
        </div>
        <div className={styles.text}>
          {months >= 12 ? t('count_years', { count: months/12 }) : t('count_months', { count: months })}
        </div>
      </div>
    </DashboardBlock>
  );
};

export default DashboardLTV;
