import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import DashboardScoring from 'components/DashboardScoring';
import { useChangeScoringMutation, useGetScoringListQuery } from 'store/workspaces/workspacesApi';

const Scoring = () => {
  const { t } = useTranslation();
  const { data, isFetching } = useGetScoringListQuery();
  const [changeScoring] = useChangeScoringMutation();

  return (
    <AdminLayout
      title="Скоринг"
      breadcrumbs={[
        { name: t('dashboard'), to: '/', as: Link },
        { name: 'Скоринг' }
      ]}
    >
      <PageTitle>{t('site_name') + ' – Скоринг'}</PageTitle>
      <DashboardScoring
        items={data}
        isLoading={isFetching}
        onChange={changeScoring}
      />
    </AdminLayout>
  );
};

export default Scoring;
