import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import LoadingIndicator from 'components/LoadingIndicator';
import WorkspaceSearchDetails from 'components/WorkspaceSearchDetails';

import { useGetWorkspaceAnalyticsSearchQuery } from 'store/analytics/analyticsApi';

const WorkspaceSearchRequests = () => {
  const sessionId = useRef(Date.now()).current;
  const { t } = useTranslation();
  const { wid } = useParams();
  const [params, setParams] = useState({});
  const { data, isLoading } = useGetWorkspaceAnalyticsSearchQuery({ wid, sessionId, from: 0, to: params.createdAt ? 299 : 99, ...params });

  const handleChangeFilter = (params) => {
    setParams(prev => ({ ...prev, ...params }));
  };

  return (
    <AdminLayout
      title={data?.user?.login}
      breadcrumbs={[
        { name: t('workspaces'), to: '/workspaces', as: Link },
        { name: t('workspace'), to: `/workspaces/${wid}`, as: Link },
        { name: t('tcrequests') }
      ]}
    >
      <PageTitle>{t('site_name') + ' – ' + t('workspaces')}</PageTitle>
      <WorkspaceSearchDetails
        items={data}
        createdAt={params.createdAt}
        createdTo={params.createdTo}
        onFilter={handleChangeFilter}
      />
      {isLoading && (
        <LoadingIndicator />
      )}
    </AdminLayout>
  );
};

export default WorkspaceSearchRequests;
