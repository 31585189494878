import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import {
  ButtonsContainer,
  LoadingButton,
  Tabs,
  DocumentName,
  FillingItemSkelet,
  calculateTotalValues,
  ThemeContext,
  mapTotalForCalc,
  mapSidesToFilling,
  Button
} from '@forma/forma-ui-kit';
import FillDocumentSide from './FillDocumentSide';

import styles from './fill-document.module.css';

const cleanSideDataForRender = (sidesData) => (
  Object.values(sidesData).map(({ tableValues, tattrErrors, tattrValues, isValid, ...rest }) => ({
    tattrValues: Object.keys(tattrValues).reduce((acc, key) => (
      tattrValues[key] ? { ...acc, [key]: tattrValues[key].replace(/<br \/>$/, '') } : acc
    ), {}),
    tableValues: tableValues?.map(table => ({ ...table, products: Object.values(table.products) })),
    ...rest
  }))
);


const FillDocument = ({
  name: defaultName = '', sides, blocksTree, onClickDownload, isDownloadLoading, valueSides, showNameInput = false,
  showWordButton = true
}) => {
  const { t } = useTranslation();
  const [currentSideId, setCurrentSideId] = useState('');
  const [sidesData, setSidesData] = useState(valueSides);
  const [isChanged, setIsChanged] = useState(false);
  const [name, setName] = useState(defaultName);

  const { currencies, lang } = useContext(ThemeContext);
  const currency = currencies[lang];

  const currentSideIndex = sides?.findIndex(({ id }) => id === currentSideId) ?? -1;

  useEffect(() => {
    if (defaultName) setName(defaultName);
  }, [defaultName]);

  useEffect(() => {
    if (valueSides)
      setSidesData(valueSides);
    else if (sides)
      setSidesData(
        sides.reduce((acc, { id, tables, tattrFolders, carticles }) => ({
          ...acc,
          [id]: {
            id,
            tattrErrors: {},
            tattrValues: {},
            tableValues: !!tables?.length ? (
              tables.map(({ id, total, showTotalRow }) => ({
                tableId: id,
                products: [],
                total: calculateTotalValues([], mapTotalForCalc({}, total, showTotalRow), currency.code),
                customProductsIds: []
              }))
            ) : null,
            articleValues: !!carticles?.length ? carticles.map(({ id, variants }) => (
              { id, value: variants.filter(({ default: byDefault }) => byDefault).map(({ id }) => id) }
            )) : null,
            isValid: !tattrFolders.length,
          },
        }), {})
      );

    if (sides?.length) setCurrentSideId(sides[0].id);
  }, [sides, valueSides, currency]);

  const handleChangeName = (name) => {
    setName(name);
  };

  const handleClickDownload = (format) => {
    if (!sidesData) return;
    onClickDownload(
      name,
      cleanSideDataForRender(sidesData),
      format,
      isChanged
    );
  };

  const selectedVariants = sidesData && Object.values(sidesData)?.reduce((acc, { articleValues }) => {
    if (!articleValues) return acc;
    return [...acc, ...articleValues.reduce((acc, { value }) => ([...acc, ...value]), [])];
  }, []);

  const sidesToFilling = sides && mapSidesToFilling(sides, blocksTree, selectedVariants);
  const sidesFillingCount = sidesToFilling?.map(({ id, tattrFolders, carticles, tables }) => {
    const sideValues = sidesData && sidesData[id];
    // const sendingTattrs = sendingData?.[id].tattrs;
    // const sendingArticles = sendingData?.[id].articles;
    // const sendingTables = sendingData?.[id].tables;

    let tattrsCount = 0;
    let filledCount = 0;

    tattrFolders.forEach(({ tattrs }) => {
      tattrs.forEach(({ id: tattrId }) => {
        tattrsCount += 1;

        if (sideValues && (
          (!!sideValues.tattrValues[tattrId] && !sideValues.tattrErrors[tattrId])
          // || (isShowSelect && sendingTattrs?.includes(tattrId))
        )) filledCount += 1;
      });
    });

    tables?.forEach(({ id }) => {
      tattrsCount += 1;
      const tableProducts = sideValues && sideValues.tableValues.find(table => id === table.tableId)?.products;
      if (
        (tableProducts && Object.keys(tableProducts).length)
        // || (isShowSelect && sendingTables?.includes(id))
      ) filledCount += 1;
    });

    carticles?.forEach(({ id }) => {
      tattrsCount += 1;
      if (sideValues && (
        sideValues.articleValues.find(article => id === article.id)?.value?.length
        // || (isShowSelect && sendingArticles?.includes(id))
      )) filledCount += 1;
    });

    return { count: filledCount, total: tattrsCount };
  });

  const isFillingValid = !!(name.length >= 3 && sidesData && sidesToFilling && Object.keys(sidesData).length === sides.length) &&
    Object.values(sidesData).every(({ id, isValid, articleValues, tableValues }) => {
      const side = sidesToFilling.find(side => String(side.id) === String(id));
      if (!side) return false;

      if (side.tattrFolders.length && !isValid) return false;


      if (side.carticles?.length) {
        if (articleValues.length < side.carticles.length) return false;
        if (!side.carticles.every(article => !!articleValues.find(({ id }) => id === article.id)?.value.length)) return false;
      }

      if (side.tables?.length) {
        if (!side.tables.every(({ id }) => {
          const tableValue = tableValues.find(({ tableId }) => tableId === id);
          return tableValue && Object.keys(tableValue.products).length;
        })) return false;
      }

      return true;
    });

  const emptyFieldsCount = sidesFillingCount?.reduce((acc, { count, total }) => acc + total - count, 0);

  return (
    <div className={styles.root}>
      <DocumentName
        name={name}
        showNameInput={showNameInput}
        onChange={handleChangeName}
      />

      <Tabs
        contentClassName="fill-document-content"
        dontRemoveContent
        current={currentSideId}
        onChange={setCurrentSideId}
        items={sidesToFilling?.length ? (
          sidesToFilling.map(({ id, name, tattrFolders, tables, carticles }, index) => {
            return {
              id,
              name,
              className: styles.tabButton,
              testId: `filling_tab_${index}`,
              bage: {
                content: sidesFillingCount && (
                  <span className={classNames(
                    styles.bage,
                    sidesFillingCount[index].total > sidesFillingCount[index].count ? styles.error : styles.success
                  )}>
                    {sidesFillingCount[index].count}/{sidesFillingCount[index].total}
                  </span>
                )
              },
              children: (
                <FillDocumentSide
                  id={id}
                  name={name}
                  tattrFolders={tattrFolders}
                  tables={tables}
                  carticles={carticles}
                  sideData={sidesData && sidesData[id]}
                  onChangeSidesData={setSidesData}
                  // sendingData={sendingData && sendingData[id]}
                  // onChangeSendingData={setSendingData}
                  // isShowSelect={isShowSelect}
                  onSetChanged={() => setIsChanged(true)}
                  key={id}
                />
              )
            };
          })
        ) : (
          [
            {
              id: '0',
              name: <span style={{ display: 'block', width: '130px' }} />,
              children: (
                <div className={styles.items}>
                  {[...Array(10)].map((key, index) => (
                    <FillingItemSkelet key={index} />
                  ))}
                </div>
              )
            }
          ]
        )}
      />
      {!!(sides && emptyFieldsCount) && (
        <div className={styles.notice}>
          <div className={styles.noticeButtons}>
            {(sides.length > 1 && currentSideIndex > 0) && (
              <Button
                className={styles.noticeButton}
                viewStyle="secondary"
                onClick={() => setCurrentSideId(sides[currentSideIndex - 1].id)}
                icon={<ReactSVG src="/icons/arrow-left.svg" wrapper="span" />}
                shadow
              >
                {sides[currentSideIndex - 1].name}
                {sidesFillingCount && (
                  <span className={classNames(
                    styles.noticeBage,
                    sidesFillingCount[currentSideIndex - 1].total > sidesFillingCount[currentSideIndex - 1].count
                      ? styles.error
                      : styles.success
                  )}>
                    {`${sidesFillingCount[currentSideIndex - 1].count}/${sidesFillingCount[currentSideIndex - 1].total}`}
                  </span>
                )}
              </Button>
            )}
          </div>
          <div className={styles.noticeContent}>
            <div className={styles.emptyFields}>
              {t('not_filled')} {t('count_fields', { count: emptyFieldsCount })}
            </div>
          </div>
          <div className={styles.noticeButtons}>
            {(sides.length > 1 && currentSideIndex < sides.length - 1) && (
              <Button
                className={styles.noticeButton}
                viewStyle="secondary"
                onClick={() => setCurrentSideId(sides[currentSideIndex + 1].id)}
                icon={<ReactSVG src="/icons/arrow-right.svg" wrapper="span" />}
                reversed
                shadow
              >
                {sides[currentSideIndex + 1].name}
                {sidesFillingCount && (
                  <span className={classNames(
                    styles.noticeBage,
                    sidesFillingCount[currentSideIndex + 1].total > sidesFillingCount[currentSideIndex + 1].count
                      ? styles.error
                      : styles.success
                  )}>
                    {`${sidesFillingCount[currentSideIndex + 1].count}/${sidesFillingCount[currentSideIndex + 1].total}`}
                  </span>
                )}
              </Button>
            )}
          </div>
        </div>
      )}
      {sides && (
        <ButtonsContainer className={styles.buttons}>
          <LoadingButton
            id="save_filled_button_pdf"
            type="submit"
            className={styles.button}
            viewStyle="secondary"
            onClick={() => handleClickDownload('pdf')}
            disabled={!isFillingValid}
            isLoading={isDownloadLoading}
            shadow
            reversed
            data-testid="save_filled_button_pdf"
            icon={<ReactSVG src="/icons/file_types/pdf.svg" wrapper="span" />}
          >
            {t('download_pdf')}
          </LoadingButton>
          {showWordButton && <LoadingButton
            id="save_filled_button_doc"
            type="submit"
            className={styles.button}
            viewStyle="secondary"
            onClick={() => handleClickDownload('doc')}
            disabled={!isFillingValid}
            isLoading={isDownloadLoading}
            shadow
            reversed
            data-testid="save_filled_button_doc"
            icon={<ReactSVG src="/icons/file_types/doc.svg" wrapper="span" />}
          >
            {t('download_docx')}
          </LoadingButton>}
        </ButtonsContainer>
      )}
    </div>
  );
};

export default FillDocument;
