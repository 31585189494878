import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Modal, Table } from '@forma/forma-ui-kit';
import VariableEdit from 'components/variables/VariableEdit';
import GroupsHeader from './GroupsHeader';
import GroupEdit from '../GroupEdit';
import GroupItem from './GroupItem';

import styles from './variables-groups.module.css';
import LoadingIndicator from 'components/LoadingIndicator';

const GroupsList = ({
  isLoading, folders, sections, variables, variblesTypes, onSave, onRemove, onAdd, onSaveVariable, onAddVariable
}) => {
  const { t } = useTranslation();
  const [ isShowModal, setShowModal ] = useState(false);
  const [ isShowModalVariable, setShowModalVariable ] = useState(false);
  const [ folderId, setFolderId ] = useState(null);

  const handleClose = () => {
    setShowModal(false);
    setFolderId(null);
  };

  const handleSave = (data) => {
    onSave(data);
    setShowModal(false);
    setFolderId(null);
  };

  const handleAdd = (data) => {
    onAdd(data);
    setShowModal(false);
    setFolderId(null);
  };

  const handleClickAddGroup = () => {
    setShowModal(true);
  };

  const handleClickEditGroup = (id) => {
    setFolderId(id);
    setShowModal(true);
  };

  const handleClickAddVariable = () => {
    setShowModalVariable(true);
  };

  const handleAddVariable = (data) => {
    onAddVariable(data);
    setShowModalVariable(false);
  };

  const handleSaveVariable = (data) => {
    onSaveVariable(data);
    setShowModalVariable(false);
  };

  return (
    <div className={styles.root}>
      <GroupsHeader
        onClickAddGroup={handleClickAddGroup}
        onClickAddVariable={handleClickAddVariable}
      />

      {isLoading && (
        <div className={styles.loader}>
          <LoadingIndicator isLoading={true} />
        </div>
      )}

      <DndProvider backend={HTML5Backend}>
        <Table className={styles.table} columns={[
          { children: t('id') },
          { children: t('name') },
          { children: '' }
        ]}>
          {folders && Object.values(folders).map(item => (
            <GroupItem
              {...item}
              folders={folders}
              sections={sections}
              variables={variables}
              variblesTypes={variblesTypes}
              onClickEdit={handleClickEditGroup}
              onRemove={onRemove}
              onSaveVariable={onSaveVariable}
              onMoveVariables={attrs => onSave({ ...item, attrs })}
              key={item.id}
            />
          ))}
        </Table>
      </DndProvider>

      <Modal
        open={isShowModal}
        onClose={handleClose}
        className={styles.root}
        title={folderId ? t('edit_group') : t('add_group')}
        closeOnDocumentClick={false}
      >
        {sections &&
          <GroupEdit
            onCancel={handleClose}
            onSave={handleSave}
            onAdd={handleAdd}
            data={folders && (
              folders[folderId] ??
              { priority: 0, id: Math.max(...Object.values(folders).map(({ id }) => Number(id)))+1 }
            )}
            sections={sections}
            variables={variables}
            folders={folders}
            variblesTypes={variblesTypes}
            isAdd={!folderId}
          />
        }
      </Modal>

      <Modal
        open={isShowModalVariable}
        onClose={() => setShowModalVariable(false)}
        className={styles.root}
        title={t('add_variable')}
      >
        {variables &&
          <VariableEdit
            variblesTypes={variblesTypes}
            onCancel={() => setShowModalVariable(false)}
            onSave={handleSaveVariable}
            onAdd={handleAddVariable}
            data={{ priority: 0, id: Math.max(...variables.map(({ id }) => Number(id)))+1 }}
            sections={sections}
            folders={folders}
            isAdd={true}
          />
        }
      </Modal>
    </div>
  );
};

export default GroupsList;
