import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import TariffsList from 'components/TariffsList';

import {
  useGetTariffsQuery,
  useUpdateTariffByIdMutation,
  useRemoveTariffByIdMutation,
  useAddTariffMutation
} from 'store/tariffs/tariffsApi';
import { setNotification } from 'store/notifications/notificationsSlice';

const Tariffs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: tariffs } = useGetTariffsQuery();
  const [ addTariff ] = useAddTariffMutation();
  const [ setTariff ] = useUpdateTariffByIdMutation();
  const [ removeTariff ] = useRemoveTariffByIdMutation();

  const handleSaveTariff = async (data) => {
    const res = await setTariff(data);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
    }
  };

  const handleAddTariff = async (data) => {
    const res = await addTariff(data);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
    }
  };

  const handleRemoveTariff = async (id) => {
    const res = await removeTariff(id);
    if (res && !res.error) {
      dispatch(setNotification(t('item_successfully_removed'), 'SUCCESS'));
    }
  };

  return (
    <AdminLayout title={t('tariffs')}>
      <PageTitle>{t('site_name') + ' – ' + t('tariffs')}</PageTitle>
      <TariffsList
        items={tariffs}
        onAdd={handleAddTariff}
        onSave={handleSaveTariff}
        onRemove={handleRemoveTariff}
      />
    </AdminLayout>
  );
};

export default Tariffs;