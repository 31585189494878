import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Tabs } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import ManagersList from 'components/demoRequests/ManagersList';
import DemoRequestsList from 'components/demoRequests/DemoRequestsList';
import DemoRequestsCalendar from 'components/demoRequests/DemoRequestsCalendar';
import DemoRequestEdit from 'components/demoRequests/DemoRequestEdit';

import {
  useSetTimeslotMutation,
  useGetBookedTimeslotsQuery,
  useGetTimeslotsQuery,
  useAddManagerMutation,
  useGetManagersQuery,
  useSetTimeslotManagerMutation,
  useDeleteManagerMutation,
  useEnableManagerTimeslotMutation,
  useDisableManagerTimeslotMutation,
  useGetManagerDisabledTimeslotsQuery
} from 'store/timeslots/timeslotsApi';
import { setNotification } from 'store/notifications/notificationsSlice';

import styles from './DemoRequests.module.css';

const DemoRequests = () => {
  const sessionId = useRef(Date.now()).current;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [ currentTab, setCurrentTab ] = useState('');
  const [ searchParams ] = useSearchParams();
  const managerId = searchParams.get('managerId');
  const timeslotId = searchParams.get('timeslotId');
  const isCreate = searchParams.get('create');

  const { data: managers } = useGetManagersQuery();
  const { data: bookedSlots } = useGetBookedTimeslotsQuery({ managerId, sessionId }, { skip: !managerId });
  const { data: timeslots } = useGetTimeslotsQuery();
  const { data: disabledTimeslots } = useGetManagerDisabledTimeslotsQuery(managerId, { skip: !managerId });

  const [ addManager ] = useAddManagerMutation();
  const [ deleteManager ] = useDeleteManagerMutation();
  const [ setTimeslot ] = useSetTimeslotMutation();
  const [ enableTimeslot ] = useEnableManagerTimeslotMutation();
  const [ disableTimeslot ] = useDisableManagerTimeslotMutation();
  const [ setTimeslotManager ] = useSetTimeslotManagerMutation();

  const handleBookTimeslot = (data) => {
    setTimeslot({
      timeslotId: data.dateTime.slot,
      clientEmail: data.clientEmail,
      clientPhone: data.clientPhone,
      clientComment: data.clientComment,
      companyName: data.companyName,
      clientFullName: data.clientFullName,
      gmt: data.dateTime.utc.utc,
      chanel: data.chanel,
      managerId: data.managerId
    });

    navigate(location.pathname + '?managerId=' + searchParams.get('managerId'));
  };

  const handleEditBook = (data) => {
    setTimeslotManager({
      timeslotId: data.dateTime.slot,
      managerId: data.managerId
    });

    navigate(location.pathname + '?managerId=' + searchParams.get('managerId'));
  };

  const handleClickTimeslot = async (slot, checked) => {
    if (!managerId) {
      dispatch(setNotification(t('select_manager_first'), 'ERROR'));
      return null;
    }

    if (checked) {
      const res = await enableTimeslot({ timeslotId: slot, managerId });
      if (res) dispatch(setNotification(t('timeslot_enabled'), 'SUCCESS'));
    } else {
      const res = await disableTimeslot({ timeslotId: slot, managerId });
      if (res) dispatch(setNotification(t('timeslot_disabled'), 'ERROR'));
    }
  };

  return (
    <AdminLayout title={t('demo_requests')}>
      <PageTitle>{t('site_name') + ' – ' + t('demo_requests')}</PageTitle>
      <div className={styles.root}>
        <div className={styles.side}>
          <ManagersList
            items={managers}
            selected={managerId}
            onAdd={addManager}
            onDelete={deleteManager}
          />
        </div>
        <div className={styles.content}>
          <Tabs
            current={currentTab}
            onChange={setCurrentTab}
            items={[
              {
                id: 'requests',
                name: t('demo_requests'),
                children: (
                  <div key="requests">
                    {!managerId && t('select_manager_for_view_list')}
                    {(timeslotId || isCreate) ? (
                      <DemoRequestEdit
                        managers={managers}
                        timeslots={timeslots}
                        data={bookedSlots?.find(item => timeslotId === item.timeslotId)}
                        onAdd={handleBookTimeslot}
                        onSave={handleEditBook}
                      />
                    ) : (
                      <DemoRequestsList items={bookedSlots} managerId={managerId} />
                    )}
                  </div>
                )
              },
              {
                id: 'timeslots',
                name: t('work_time'),
                children: (
                  <div key="timeslots">
                    <DemoRequestsCalendar
                      timeslots={timeslots}
                      disabledTimeslots={disabledTimeslots}
                      bookedSlots={bookedSlots}
                      onClickTimeslot={handleClickTimeslot}
                    />
                  </div>
                )
              }
            ]}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default DemoRequests;
