import DashboardBlock from 'components/Dashboard/DashboardBlock';

import styles from './payments-conversion.module.css';

const PaymentsConversion = ({ day, week, month }) => (
  <DashboardBlock
    title="Средняя конверсия в оплату"
  >
    <div className={styles.root}>
      <div className={styles.item}>
        <div className={styles.itemValue}>
          {day.toFixed()}% {/* <img src="/icons/chevron-double-up.svg" alt="" /> */}
        </div>
        <div className={styles.itemLabel}>
        Сегодня
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.itemValue}>
          {week.toFixed()}% {/* <img src="/icons/chevron-double-up.svg" alt="" /> */}
        </div>
        <div className={styles.itemLabel}>
        Неделя
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.itemValue}>
          {month.toFixed()}% {/* <img src="/icons/chevron-double-up.svg" alt="" /> */}
        </div>
        <div className={styles.itemLabel}>
        Месяц
        </div>
      </div>
    </div>
  </DashboardBlock>
);

export default PaymentsConversion;
