import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import styles from './registers-chart.module.css';

const CustomTick = ({ payload, index, tickFormatter, visibleTicksCount, width, x, ...props }) => {
  const value = payload.value.split(' ');
  return (
    <text {...props}>
      <tspan x={x} dy={20}>{value[1]}</tspan>
      <tspan x={x} dy={15} fontWeight={700}>{value[0]}</tspan>
    </text>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div>
        <div>{payload[0].value}</div>
        <div style={{ color: payload[0].color }}>{payload[0].payload.diff > 0 && '+'}{payload[0].payload.diff}</div>
      </div>
    );
  }

  return null;
};

const RegistersChart = ({ data, total }) => {
  return (
    <div className={styles.root}>
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 35,
              left: 5,
              bottom: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" fontSize="14px" includeHidden minTickGap={1} tick={<CustomTick />} />
            <YAxis width={20} fontSize="14px" />
            <Tooltip
              content={<CustomTooltip />}
              cursor={false}
            />
            <Line
              type="monotone"
              dataKey="value"
              stroke="var(--primary-color)"
              animationDuration={500}
              dot={({ dataKey, ...props }) => {
                return <circle {...props} r={5} strokeWidth={2}></circle>;
              }}
              activeDot={({ dataKey, ...props }) => {
                return <circle {...props} r={5} strokeWidth={2}></circle>;
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.footer}>
        <span className={styles.footerLabel}>Всего:</span>
        <span className={styles.footerValue}>{total}</span>
      </div>
    </div>
  );
};
  // <DashboardBlock
  //   title="Неделя"
  // >
  //   <div className={styles.root}>
  //     <div className={styles.value}>{count}</div>
  //     <div className={styles.text}>
  //       оплат на <span className={classNames(styles.price)}>{formatPrice(amount, currencies.ru)}</span> {/* <img src="/icons/chevron-double-up.svg" alt="" /> */}
  //     </div>
  //   </div>
  // </DashboardBlock>

export default RegistersChart;
