import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import StrictTemplateEdit from 'entities/strictTemplates/ui/StrictTemplateEdit';

import {
  useGetStrictTemplateByIdQuery,
  // useRemoveStrictTemplateMutation,
  // useUpdateStrictTemplateMutation,
  // useUploadStrictTemplateMutation
} from 'entities/strictTemplates/api/strictTemplatesApi';

// import { setNotification } from 'store/notifications/notificationsSlice';

const TemplateEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  // const dispatch = useDispatch();

  const { data: template, isFetching, isError } = useGetStrictTemplateByIdQuery(id!);
  // const [ updateTemplate ] = useUpdateStrictTemplateMutation();
  // const [ uploadTemplate ] = useUploadStrictTemplateMutation();
  // const [ removeTemplate ] = useRemoveStrictTemplateMutation();

  useEffect(() => {
    if (!isFetching && isError) navigate('/404');
    // eslint-disable-next-line
  }, [isError, isFetching]);

  // const handleSaveTemplate = async ({ id, lang, value }) => {
  //   const res = await updateTemplate({ id, lang, value });
  //   if (res && !res.error) {
  //     dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
  //   }
  // };

  return (
    <AdminLayout
      title={t('strict_templates')}
      breadcrumbs={[{ name: t('strict_templates'), to: '/templates', as: Link }, { name: template?.name! }]}
    >
      <PageTitle>{t('site_name') + ' – ' + t('strict_templates')}</PageTitle>
      <StrictTemplateEdit />
    </AdminLayout>
  )
};

export default TemplateEdit;
