import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import DevicesList from 'components/DevicesList';
import LoadingIndicator from 'components/LoadingIndicator';

import { useGetWorkspaceAnalyticsDevicesQuery } from 'store/analytics/analyticsApi';

import styles from './UserDevices.module.css';

const UserDevices = () => {
  const sessionId = useRef(Date.now()).current;
  const { t } = useTranslation();
  const { wid, uid } = useParams();

  const { data, isLoading } = useGetWorkspaceAnalyticsDevicesQuery({ wid, uid, sessionId }, { skip: !wid || !uid });

  return (
    <AdminLayout
      title="Устройства, с которых выполнен вход"
      breadcrumbs={[
        { name: t('workspaces'), to: '/workspaces', as: Link },
        { name: t('workspace'), to: `/workspaces/${wid}`, as: Link },
        { name: 'Устройства, с которых выполнен вход' }
      ]}
    >
      <PageTitle>{t('site_name') + ' – ' + t('workspaces')}</PageTitle>
      <div className={styles.root}>
        <DevicesList items={data} />
      </div>
      {isLoading && (
        <LoadingIndicator />
      )}
    </AdminLayout>
  );
};

export default UserDevices;
