import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, RemoveModal, Table, TableCell, TableRow } from '@forma/forma-ui-kit';
import StrictTemplatesHeader from './StrictTemplatesHeader';
import { IStrictTemplateItem } from '../../model/types';

import styles from './strictTemplatesList.module.css';

interface StrictTemplatesListProps {
  items?: IStrictTemplateItem[],
  onSearch: (value: string) => void,
  onCreate: (name: string) => void,
  onRemove: (id: string) => void
}

interface StrictTemplatesListItemProps extends IStrictTemplateItem {
  onRemove: (id: string) => void
}

const StrictTemplatesListItem = ({ id, name, onRemove }: StrictTemplatesListItemProps) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell className={classNames(styles.cellLink, styles.name)} title={t('name')}>
        <Link to={`/strict-templates/${id}`}>
          {name}
        </Link>
      </TableCell>
      <TableCell className={styles.buttons}>
        <div>
          {/* <Button
            viewStyle="text"
            onClick={() => setEditOpen(true)}
            icon={<ReactSVG src="/icons/edit.svg" />}
            shadow
          /> */}
          {/* <TemplateCategoryEditModal
            open={isEditOpen}
            onClose={() => setEditOpen(false)}
            title={t('edit_category')}
            data={{ id, name, parentid}}
            categories={categories?.filter(item => item.id !== id)}
            onSave={values => onSave({ id, ...values })}
          /> */}
          <RemoveModal
            control={
              <Button viewStyle="text" className={styles.remove} iconClassName={styles.removeIcon} icon={<ReactSVG src="/icons/trash.svg" />} />
            }
            title={t('remove_template')}
            onRemove={() => onRemove(id)}
            itemName={name}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

const StrictTemplatesList = ({ items, onCreate, onRemove, onSearch }: StrictTemplatesListProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <StrictTemplatesHeader onChangeSearch={onSearch} onCreate={onCreate} />
      <Table className={styles.table} columns={[
        { children: t('name') },
        { children: '' }
      ]}>
        {items?.map(item => (
          <StrictTemplatesListItem
            {...item}
            onRemove={onRemove}
            key={item.id}
          />
        ))}
      </Table>
    </div>
  );
};

export default StrictTemplatesList;
