import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, RemoveModal, Table, TableRow, TableCell } from '@forma/forma-ui-kit';
import AdminsListHeader from './AdminsListHeader';
import AdminEditModal from './AdminEditModal';

import styles from './admins-list.module.css';

const AdminsList = ({ users, pagination, onChangeSearch, onChangeFilter, onChangeUserGroups, onRemove }) => {
  const { t } = useTranslation();
  const [ isShowModal, setShowModal ] = useState(false);
  const [ editAdminId, setEditAdminId ] = useState(null);

  const handleCloseModal = () => {
    setShowModal(false);
    setEditAdminId(null);
  };

  // const handleClickItem = (id) => {
  //   setShowModal(true);
  //   setEditAdminId(id);
  // };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <AdminsListHeader
          onChangeSearch={onChangeSearch}
          onChangeFilter={onChangeFilter}
          onClickAdd={() => setShowModal(true)}
        />
      </div>
      <div className={styles.content}>
        {/* <PaginationWrapper {...pagination}> */}
        <Table className={styles.table} columns={[
          { children: t('fullname_short') },
          { children: t('email_short') },
          { children: t('group') }
        ]}>
          {!!users?.length && (
            users.map(({ id, name, login, role }) => (
              <TableRow key={id}>
                <TableCell className={styles.fio} title={t('fullname_short')}>
                  <Link to={`/users/${id}`} className={styles.link}>{name}</Link>
                </TableCell>
                <TableCell className={styles.email} title={t('email_short')}>
                  <Link to={`/users/${id}`} className={styles.link}>{login}</Link>
                </TableCell>
                <TableCell className={styles.group} title={t('group')}>
                  <Link to={`/users/${id}`} className={styles.link}>{role}</Link>
                </TableCell>
                {/* <TableCell className={styles.group} title={t('group')}>
                    {groups &&
                      <div className={styles.select}>
                        <Select
                          name={`usergroups_${id}`}
                          multiple
                          options={groups.map(({ id, name }) => ({ label: name, value: id }))}
                          onChange={e => onChangeUserGroups(id, e.target.value.split(','))}
                          value={usergroups.map(groupId => {
                            if (groups && !groups.find(group => group.id === groupId)) return null;
                            return groupId;
                          }).join(',')}
                        />
                      </div>
                    }
                  </TableCell> */}
                <TableCell className={styles.buttons}>
                  <RemoveModal
                    title={t('deleting_user')}
                    control={
                      <Button
                        title={t('delete')}
                        className={styles.remove}
                        viewStyle="textLight"
                        icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
                      />
                    }
                    onRemove={() => onRemove(id)}
                    message={t('do_yo_want_delete_user')}
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </Table>
        {/* </PaginationWrapper> */}
      </div>

      <AdminEditModal
        open={isShowModal}
        onClose={handleCloseModal}
        id={editAdminId}
      />
    </div>
  );
};

export default AdminsList;
