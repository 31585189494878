import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITemplateSide, Modal, type ITemplatesListSides } from '@forma/forma-ui-kit';
import DistributeSides, { type SortSide } from 'components/DistributeSides';

import styles from './paste-elements-modal.module.css';

interface PasteElementsModalProps {
  open: boolean,
  onClose: () => void,
  sides: ITemplateSide[],
  pastedSides: ITemplateSide[],
  onSave: (sides: ITemplateSide[], sidesMapIds: { oldId: string, newId: string }[]) => void
}

const PasteElementsModal: FC<PasteElementsModalProps> = ({
  open, onClose, sides, pastedSides, onSave
}) => {
  const { t } = useTranslation();
  const [ sortedSides, setSortedSides ] = useState<SortSide[]>(sides.map(({ id, name }) => ({ id, name, sideIndices: [] })));

  useEffect(() => {
    setSortedSides(sides.map(({ id, name }) => ({ id, name, sideIndices: [] })));
  }, [sides]);

  const templatesSides: ITemplatesListSides = useMemo(
    () => ({
      'tmpId': {
        id: 'tmpId',
        name: t('paste_in_template'),
        sides: pastedSides ?? []
      }
    }),
    // eslint-disable-next-line
    [pastedSides]
  );

  const sidesCount = sortedSides ? sortedSides?.reduce((prev, current) => (prev + current.sideIndices.length), 0) : 0;
  const templatesSidesCount =
    templatesSides ? Object.values(templatesSides).reduce((prev, current) => (prev + current.sides.length), 0) : 0;

  const handleSave = () => {
    if (!pastedSides) return;

    const sidesMapIds: { oldId: string, newId: string }[] = [];
    const mappedSides = sortedSides.map(({ id, name, sideIndices }) => {
      const sideData: ITemplateSide = { id, name, tattrFolders: [], tables: [], carticles: [] };
      sideIndices.forEach(({ sideIndex }) => {
        sidesMapIds.push({ oldId: pastedSides[sideIndex].id, newId: id });
        sideData.carticles.push(...pastedSides[sideIndex].carticles);
        sideData.tables.push(...pastedSides[sideIndex].tables);
        sideData.tattrFolders.push(...pastedSides[sideIndex].tattrFolders);
      });
      return sideData;
    });

    onSave(mappedSides, sidesMapIds);

    onClose();
  };

  return (
    <Modal
      size="large"
      open={open}
      onClose={onClose}
      title={t('distribute_sides')}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      showClose={false}
      buttons={[{
        children: t('save'),
        className: styles.button,
        disabled: sidesCount !== templatesSidesCount,
        onClick: handleSave,
        closing: false
      }]}
    >
      <div className={styles.content}>
        <DistributeSides
          templatesSides={templatesSides}
          sortedSides={sortedSides}
          onSortSides={setSortedSides}
        />
      </div>

    </Modal>
  );
};

export default PasteElementsModal;
