import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, Link } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import WorkspaceInfo from 'components/WorkspaceInfo';

import { useAddExternalInfillEntityMutation, useGetExternalInfillEntitiesQuery, useGetTokenMutation, useRemoveExternalInfillEntityMutation, useRemoveWorkspaceMutation, useSetExternalInfillEntityApprovedMutation } from 'store/workspaces/workspacesApi';
import {
  useGetWorkspaceAnalyticsQuery,
  useGetWorkspaceAnalyticsGraphQuery,
  useGetWorkspaceAnalyticsCRMQuery,
  useAddFavouriteWorkspaceMutation,
  useRemoveFavouriteWorkspaceMutation
} from 'store/analytics/analyticsApi';
import { useDownloadTcRequestsArchiveMutation, useDownloadTcRequestsFileMutation } from 'store/tcRequests/tcRequestsApi';
import { setNotification } from 'store/notifications/notificationsSlice';

const siteUrl = process.env.REACT_APP_LK_URL;

const Workspace = () => {
  const sessionId = useRef(Date.now()).current;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { wid } = useParams();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [params, setParams] = useState({});
  const userIdParam = selectedUserId ? { uid: selectedUserId } : {};

  const { data } = useGetWorkspaceAnalyticsQuery({ wid, sessionId, ...userIdParam });
  const { data: crm } = useGetWorkspaceAnalyticsCRMQuery({ wid, sessionId });
  const { data: graph } = useGetWorkspaceAnalyticsGraphQuery({ wid, sessionId, ...userIdParam, ...params });
  const { data: entities } = useGetExternalInfillEntitiesQuery(wid);
  const [getToken] = useGetTokenMutation();
  const [downloadFile] = useDownloadTcRequestsFileMutation();
  const [downloadTcRequestsArchive] = useDownloadTcRequestsArchiveMutation();
  const [addFavourite] = useAddFavouriteWorkspaceMutation();
  const [removeFavourite] = useRemoveFavouriteWorkspaceMutation();
  const [removeWorkspace] = useRemoveWorkspaceMutation();
  const [approveEntity] = useSetExternalInfillEntityApprovedMutation();
  const [addEntity] = useAddExternalInfillEntityMutation();
  const [removeEntity] = useRemoveExternalInfillEntityMutation();

  const handleClickSignAs = () => {
    getToken({ wid }).then(result => {
      if (result.data?.token) window.open(`${siteUrl}/auth?token=${result.data.token}`, '_blank');
    });
  };

  const handleDownloadTemplate = (id, fileName) => {
    downloadFile(id).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${fileName}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(e => { });
  };

  const handleDownloadRequests = (workspaceId) => {
    downloadTcRequestsArchive({ wid: workspaceId, notProcessed: true }).unwrap().then(url => {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${t('tcrequest_file')} ${workspaceId}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(e => { });
  };

  const handleChangeFilter = (label, value) => {
    setParams(prev => ({ ...prev, [label]: value }));
  };

  const handleRemoveWorkspace = async (id) => {
    const res = await removeWorkspace(id);
    if ('data' in res) {
      dispatch(setNotification(t('workspace_was_deleted'), 'ERROR'));
      navigate('/workspaces');
    }
  };

  const handleSubmitEntity = async (data) => {
    await approveEntity(data);
  };

  const handleRemoveEntity = async (data) => {
    const res = await removeEntity(data);
    if ('data' in res) {
      dispatch(setNotification('Организация удалена', 'ERROR'));
    }
  };

  const handleAddEntity = async (data) => {
    const res = await addEntity(data);
    if ('data' in res) {
      dispatch(setNotification('Организация добавлена', 'SUCCESS'));
    }
  };

  return (
    <AdminLayout
      title={data?.user?.login}
      breadcrumbs={[{ name: t('workspaces'), to: '/workspaces', as: Link }, { name: data?.user?.login }]}
    >
      <PageTitle>{t('site_name') + ' – ' + t('workspaces')}</PageTitle>
      <WorkspaceInfo
        id={wid}
        data={data}
        params={params}
        statistic={graph}
        entities={entities}
        crm={crm}
        onChangeFilter={handleChangeFilter}
        onClickSignAs={handleClickSignAs}
        onClickDownloadRequest={handleDownloadTemplate}
        onClickDownloadRequests={handleDownloadRequests}
        selectedUserId={selectedUserId}
        onSelectUser={setSelectedUserId}
        onAddFavourite={wid => addFavourite({ wid })}
        onRemoveFavourite={wid => removeFavourite({ wid })}
        onRemove={handleRemoveWorkspace}
        onSubmitEntity={handleSubmitEntity}
        onRemoveEntity={handleRemoveEntity}
        onAddEntity={handleAddEntity}
      />
    </AdminLayout>
  );
};

export default Workspace;
