import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal } from '@forma/forma-ui-kit';
import SearchInput from 'components/SearchInput';

import styles from './strictTemplatesHeader.module.css';

interface StrictTemplatesHeaderProps {
  onChangeSearch: (value: string) => void,
  onCreate: (name: string) => void,
}

const StrictTemplatesHeader = ({ onChangeSearch, onCreate }: StrictTemplatesHeaderProps) => {
  const { t } = useTranslation();
  const [ name, setName ] = useState('');

  return (
    <div className={styles.root}>
      <SearchInput
        onSearch={onChangeSearch}
        className={styles.search}
        placeholder={t('name')}
      />
      <Modal
        size="small"
        title={t('create_template')}
        onClose={() => setName('')}
        control={
          <Button
            viewStyle="secondary"
            shadow
          >
            {t('create_template')}
          </Button>
        }
        buttons={[
          {
            children: t('cancel'),
            viewStyle: 'tertiary'
          },
          {
            children: t('create'),
            viewStyle: 'primary',
            disabled: name.length < 3,
            onClick: () => onCreate(name)
          },
        ]}
      >
        <Input
          name="template_name"
          onChange={e => setName(e.target.value)}
          value={name}
          placeholder={t('name')}
        />
      </Modal>
    </div>
  );
};

export default StrictTemplatesHeader;
