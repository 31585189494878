import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import FilesEditor from 'components/FilesEditor';
import { Modal, Button, ButtonsContainer } from '@forma/forma-ui-kit';

import {
  useGetSharedTemplateQuery,
  useGetSharedTemplateContentQuery,
  useSaveSharedTemplateContentMutation
} from 'store/templates/templatesApi';
import {
  useGetTemplateSectionsGroupsQuery,
  useGetTemplateSectionsVariablesQuery,
} from 'store/schemas/schemasApi';
// import { useGetProductsQuery } from 'redux/products/productsApi';

import styles from './Editor.module.css';

const Editor = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isShowModalSuccess, setShowModalSuccess] = useState(false);

  const { data: template } = useGetSharedTemplateQuery(id);
  const { data: templateContent } = useGetSharedTemplateContentQuery(id, { skip: !id });
  const { data: availableVariables, refetch: refetchVars } = useGetTemplateSectionsVariablesQuery();
  const { data: variablesGroups, refetch: refetchGroups } = useGetTemplateSectionsGroupsQuery();
  // const { data: products } = useGetProductsQuery({ from: 0, to: 3 });

  useEffect(() => {
    const updateVars = (e) => {
      if (e.key !== 'updateVars') return;
      refetchVars();
      refetchGroups();
    };

    window.addEventListener('storage', updateVars);
    return () => window.removeEventListener('storage', updateVars);

    // eslint-disable-next-line
  }, []);

  // const [ changeTemplate, { isLoading: isChangeLoading } ] = useChangeFolderMutation();
  const [saveTemplate, { isLoading: isSaveLoading }] = useSaveSharedTemplateContentMutation();

  const handleSaveContent = async (data, showModal) => {
    // if (templateName !== template.name) changeTemplate({ id: template.id, name: templateName, type: 'template', folderid: template?.folderid ?? null });
    const result = await saveTemplate(data);
    if (result.data && showModal) setShowModalSuccess(true);

    return !!(result && 'data' in result);
  };

  return (
    <AdminLayout
      title={t('shared_templates')}
      breadcrumbs={[{ name: t('shared_templates'), to: '/templates', as: Link }, { name: template?.name }]}
    >
      <PageTitle>{t('site_name') + ' – ' + t('shared_templates')}</PageTitle>
      <FilesEditor
        template={templateContent}
        id={template?.id}
        onSave={handleSaveContent}
        variablesGroups={variablesGroups}
        availableVariables={availableVariables}
        isSaveLoading={isSaveLoading}
      // products={products?.products}
      />

      <Modal
        open={isShowModalSuccess}
        onClose={() => setShowModalSuccess(false)}
        title={t('template_success_saved')}
      >
        <div className={styles.modalSuccess}>
          <ButtonsContainer className={styles.modalButtons}>
            <Button as={Link} onClick={() => setShowModalSuccess(false)} to={'/templates/' + id}>{t('edit_description')}</Button>
            <Button as={Link} viewStyle="tertiary" onClick={() => setShowModalSuccess(false)} to={'/fill-template/' + id}>{t('fill_template')}</Button>
          </ButtonsContainer>
          <ButtonsContainer className={styles.modalButtons}>
            <Button onClick={() => setShowModalSuccess(false)}>{t('continue_editing')}</Button>
            <Button as={Link} viewStyle="tertiary" onClick={() => setShowModalSuccess(false)} to={'/templates'}>{t('go_to_templates')}</Button>
          </ButtonsContainer>
        </div>
      </Modal>
    </AdminLayout>
  );
};

export default Editor;
