import { useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import { LoadingButton } from '@forma/forma-ui-kit';

import {
  useSetEditorStylesMutation
} from 'store/resources/resourcesApi';
import { setNotification } from 'store/notifications/notificationsSlice';
import { ButtonsContainer } from '@forma/forma-ui-kit';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

const EDITOR_STYLES_URL = process.env.REACT_APP_EDITOR_STYLES_URL;
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

const EditorStyles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ content, setContent ] = useState('');

  const [ uploadFile, { isLoading } ] = useSetEditorStylesMutation();

  const getStyles = () => fetch(EDITOR_STYLES_URL + '?v=' + REACT_APP_VERSION).then(res => res.text()).then(css => setContent(css));

  useEffect(() => {
    getStyles();
  }, []);

  const handleChangeFile = e => {
    if (e.target.files) ;
    const reader = new FileReader();
    reader.readAsText(e.target.files[0]);
    reader.onload = function() {
      setContent(reader.result);
    };
  };

  const handleClickUpload = async () => {
    const file = new File([content], 'default-styles.css', { lastModified: new Date() });

    const formData = new FormData();
    formData.append('file', file);
    const res = await uploadFile(formData);
    if (res && !res.error) {
      dispatch(setNotification(t('successful'), 'SUCCESS'));
    }
  };

  return (
    <AdminLayout title={t('editor_styles')}>
      <PageTitle>{t('site_name') + ' – ' + t('editor_styles')}</PageTitle>
      <input type="file" onChange={handleChangeFile} accept=".css" />
      <br />
      <br />
      <AceEditor
        mode="css"
        theme="github"
        onChange={setContent}
        name="styles"
        width="100%"
        editorProps={{ $blockScrolling: true }}
        value={content}
      />

      <ButtonsContainer style={{ justifyContent: 'flex-end' }}>
        <LoadingButton isLoading={isLoading} onClick={handleClickUpload} disabled={!content.length}>{t('save')}</LoadingButton>
      </ButtonsContainer>
    </AdminLayout>
  );
};

export default EditorStyles;
