import { useRef } from 'react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
// import { PopupPosition, EventType, PopupActions } from 'reactjs-popup/dist/types';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import styles from './context-menu.module.css';

// interface ContextMenuProps {
//   open?: boolean,
//   control: ReactElement|((open: boolean) => ReactElement),
//   items: any[], //fixme
//   mouseLeaveDelay?: number,
//   offsetX?: number,
//   offsetY?: number,
//   onOpen?: () => void,
//   onClose?: () => void,
//   onClickItem?: ((e: MouseEvent<HTMLAnchorElement|HTMLButtonElement|HTMLLinkElement>) => void)|null,
//   on?: EventType[]|EventType,
//   position?: PopupPosition[]|PopupPosition,
// }

const ContextMenu = ({
  open, control, items, mouseLeaveDelay, offsetX = 0, offsetY = 0, onOpen, onClose, onClickItem,
  on = [ 'click' ], position = [ 'bottom left', 'top left', 'bottom right', 'top right' ]
}) => {
  const ref = useRef(null);
  // const ref = useRef<PopupActions>(null);

  if (!items || !items.length) return null;

  return (
    <Popup
      on={on}
      open={open}
      trigger={control}
      className="options"
      position={position}
      arrow={false}
      offsetY={offsetY}
      offsetX={offsetX}
      onOpen={onOpen}
      onClose={onClose}
      mouseLeaveDelay={mouseLeaveDelay}
      // keepTooltipInside={true}
      ref={ref}
      nested
    >
      <div className={styles.list}>
        <input type="hidden" style={{ display: 'none' }} autoFocus />
        {items.map(({ link, items, text, icon, disabled, onClick, onMouseEnter, onMouseLeave, align }, index) => {
          const handleClickItem = () => {
            if (onClick) onClick();
            if (ref.current) ref.current.close();
          };

          if (items) return (
            <ContextMenu
              control={open => (
                <button
                  className={classNames(styles.item, open && styles.active)}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  key={index}
                >
                  {text} <ReactSVG className={styles.icon} src="/icons/arrow-right-bold.svg" wrapper="span" />
                </button>
              )}
              on={[ 'hover', 'focus' ]}
              items={items}
              onClickItem={ref.current && ref.current.close}
              position={
                align === 'left' ?
                  [ 'left top', 'left bottom', 'left center', 'right top', 'right bottom', 'right center' ]
                  : [ 'right top', 'right bottom', 'right center', 'left top', 'left bottom', 'left center' ]
              }
              mouseLeaveDelay={200}
              offsetX={12}
              key={index}
            />
          );

          if (link) return (
            <Link
              className={classNames(styles.item, disabled && styles.disabled)}
              to={link}
              onClick={e => { handleClickItem(); onClickItem && onClickItem(e); }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              key={index}
            >
              {text} {icon}
            </Link>
          );

          return (
            <button
              className={classNames(styles.item, disabled && styles.disabled)}
              onClick={e => { handleClickItem(); onClickItem && onClickItem(e); }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              key={index}
            >
              {text} {icon}
            </button>
          );
        })}
      </div>
    </Popup>
  );
};

export default ContextMenu;
