import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import StrictTemplatesList from 'entities/strictTemplates/ui/StrictTemplatesList';

import {
  useAddStrictTemplateMutation,
  useGetStrictTemplatesQuery,
  useRemoveStrictTemplateMutation,
} from 'entities/strictTemplates/api/strictTemplatesApi';

import { setNotification } from 'store/notifications/notificationsSlice';

const Templates = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ search, setSearch ] = useState('');

  const { data: templates } = useGetStrictTemplatesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      data: data?.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase())),
    }),
  });

  const [ addTemplate ] = useAddStrictTemplateMutation();
  const [ removeTemplate ] = useRemoveStrictTemplateMutation();

  const handleCreateTemplate = async (name: string) => {
    const res = await addTemplate({ name, sides: [], blocksTree: { carticles: [], tattrs: [], tables: [] } });
    if ('data' in res) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
    }
  };

  const handleRemoveTemplate = async (id: string) => {
    const res = await removeTemplate(id);
    if ('data' in res) {
      dispatch(setNotification(t('item_successfully_removed'), 'SUCCESS'));
    }
  };

  return (
    <AdminLayout title={t('strict_templates')}>
      <PageTitle>{t('site_name') + ' – ' + t('strict_templates')}</PageTitle>
      <StrictTemplatesList
        items={templates}
        onCreate={handleCreateTemplate}
        onRemove={handleRemoveTemplate}
        onSearch={setSearch}
      />
    </AdminLayout>
  )
};

export default Templates;