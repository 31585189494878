import * as Main from 'pages/Main';
import * as Account from 'pages/Account';
import * as Common from 'pages/Common';
import * as Variables from 'pages/Variables';
import * as Resources from 'pages/Resources';
import * as Requests from 'pages/Requests';
import * as Settings from 'pages/Settings';
import * as Tariffs from 'pages/Tariffs';
import * as Users from 'pages/Users';
import * as Templates from 'pages/Templates';
import * as StrictTemplates from 'pages/StrictTemplates';

export const routes = [
  { path: '/login', element: <Account.Login />, logged: false, loggedRedirect: '/' },

  { path: '/', element: <Requests.RequestsKanban />, exact: true, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/dashboard', element: <Main.Main />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/registers', element: <Main.Registers />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/payments', element: <Main.Payments />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/scoring', element: <Main.Scoring />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },

  { path: '/variables', element: <Variables.Variables />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/variables/:id', element: <Variables.Variable />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/groups-variables', element: <Variables.Folders />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/sections-variables', element: <Variables.Sections />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },

  { path: '/resources', element: <Resources.Resources />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/editor-styles', element: <Resources.EditorStyles />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },

  { path: '/requests', element: <Requests.RequestsKanban />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/requests/file/:id', element: <Requests.FileDownload />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  // { path: '/requests/board', element: <Requests.RequestsKanban />, logged: true, loggedRedirect: '/templates' },

  { path: '/folders/:id', element: <Templates.Folder />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN', 'TEST_ADMIN'] },

  { path: '/templates', element: <Templates.Templates />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN', 'TEST_ADMIN'] },
  { path: '/templates/:id', element: <Templates.Template />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN', 'TEST_ADMIN'] },
  { path: '/templates-categories', element: <Templates.TemplateCategories />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/editor/:id', element: <Templates.Editor />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN', 'TEST_ADMIN'] },
  { path: '/fill-template/:id', element: <Templates.Filling />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN', 'TEST_ADMIN'] },

  { path: '/strict-templates', element: <StrictTemplates.Templates />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN', 'TEST_ADMIN'] },
  { path: '/strict-templates/:id', element: <StrictTemplates.TemplateEdit />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN', 'TEST_ADMIN'] },

  { path: '/profile', element: <Settings.Profile />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/admins', element: <Settings.Admins />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },

  { path: '/tariffs', element: <Tariffs.Tariffs />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/promocodes', element: <Tariffs.Promocodes />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },

  { path: '/workspaces', element: <Users.Workspaces />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/workspaces/:wid', element: <Users.Workspace />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/workspaces/:wid/filling-requests', element: <Users.WorkspaceFillingRequests />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/workspaces/:wid/search-requests', element: <Users.WorkspaceSearchRequests />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/workspaces/:wid/devices/:uid', element: <Users.UserDevices />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },
  { path: '/demo-requests', element: <Users.DemoRequests />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN'] },

  { path: '/converters', element: <Common.Converters />, logged: true, loggedRedirect: '/templates', roles: ['COMMON_ADMIN', 'TEST_ADMIN'] },

  { path: '*', element: <Common.NotFound />, skip_auth: true },
];
