import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import VariablesList from 'components/variables/VariablesList';

import {
  useGetStrictVariablesQuery,
  useAddStrictVariableMutation,
  useUpdateStrictVariableMutation,
  useRemoveStrictVariableMutation,
  useGetStrictSectionsQuery,
  useGetStrictFoldersQuery,
  useGetVariablesUsedTemplatesQuery
} from 'store/variables/variablesApi';
import { useGetTemplateVariablesTypesQuery } from 'store/schemas/schemasApi';
import { setNotification } from 'store/notifications/notificationsSlice';

const Variables = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ search, setSearch ] = useState(null);

  const { data: variables } = useGetStrictVariablesQuery(undefined);
  const { data: variablesTemplates } = useGetVariablesUsedTemplatesQuery();

  const { data: sections } = useGetStrictSectionsQuery();
  const { data: folders } = useGetStrictFoldersQuery();
  const { data: variblesTypes } = useGetTemplateVariablesTypesQuery();
  const [ addVariable ] = useAddStrictVariableMutation();
  const [ updateVariable ] = useUpdateStrictVariableMutation();
  const [ removeVariable ] = useRemoveStrictVariableMutation();

  const handleSaveVariable = async (values) => {
    const data = { ...values };
    delete(data.lang);
    const res = await updateVariable(data);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
      localStorage.setItem('updateVars', new Date().toUTCString());
    }
  };

  const handleAddVariable = async (values) => {
    const data = { ...values };
    delete(data.lang);
    const res = await addVariable(data);
    if (res && !res.error) {
      dispatch(setNotification(t('data_successfully_changed'), 'SUCCESS'));
      localStorage.setItem('updateVars', new Date().toUTCString());
    }
  };

  const handleRemoveVariable = async (id) => {
    const res = await removeVariable(id);
    if (res && !res.error) {
      dispatch(setNotification(t('item_successfully_removed'), 'SUCCESS'));
      localStorage.setItem('updateVars', new Date().toUTCString());
    }
  };

  const handleChangeSearch = (value) => {
    setSearch(value);
  };

  const filtered = variables && (search ? Object.values(variables).filter(({ name }) => name.match(new RegExp(search, 'gi'))) : variables);

  return (
    <AdminLayout title={t('variables')}>
      <PageTitle>{t('site_name') + ' – ' + t('variables')}</PageTitle>
      <VariablesList
        variables={filtered}
        variblesTypes={variblesTypes}
        sections={sections}
        folders={folders}
        variablesTemplates={variablesTemplates}
        onAdd={handleAddVariable}
        onSave={handleSaveVariable}
        onSearch={handleChangeSearch}
        onRemove={handleRemoveVariable}
        lastId={variables && Math.max(...variables.map(({ id }) => Number(id)))}
      />
    </AdminLayout>
  );
};

export default Variables;