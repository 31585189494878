import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import TemplateCategoriesList from 'components/TemplateCategoriesList';

import {
  useAddSharedTemplatesCategoryMutation,
  useGetSharedTemplatesCategoriesQuery,
  useRemoveSharedTemplatesCategoryMutation,
  useSaveSharedTemplatesCategoryMutation,
} from 'store/templates/templatesApi';

const TemplateCategories = () => {
  const { t } = useTranslation();

  const { data: templateCategories } = useGetSharedTemplatesCategoriesQuery();
  const [addCategory] = useAddSharedTemplatesCategoryMutation();
  const [saveCategory] = useSaveSharedTemplatesCategoryMutation();
  const [removeCategory] = useRemoveSharedTemplatesCategoryMutation();

  return (
    <AdminLayout
      title={t('shared_templates')}
      breadcrumbs={[{ name: t('shared_templates'), to: '/templates', as: Link }, { name: t('categories') }]}
    >
      <PageTitle>{t('site_name') + ' – ' + t('shared_templates')}</PageTitle>
      <TemplateCategoriesList
        items={templateCategories}
        onAdd={addCategory}
        onSave={saveCategory}
        onRemove={removeCategory}
      />
    </AdminLayout>
  );
};

export default TemplateCategories;
