import { useSelector } from 'react-redux';
import classNames from 'classnames';
import SideMenu from 'components/SideMenu';
import { Breadcrumbs, BreadcrumbsItem } from '@forma/forma-ui-kit';
import { selectMenuOpen } from 'store/common/commonSlice';

import styles from './admin-layout.module.css';

interface AdminLayoutProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  breadcrumbs?: BreadcrumbsItem[];
  titleButtons?: React.ReactNode;
  breadcrumbsPlaceholder?: string;
  onBreadCrumbsChange?: (value: string) => void;
}

const AdminLayout = ({ children, className, title, breadcrumbs, titleButtons, breadcrumbsPlaceholder, onBreadCrumbsChange }: AdminLayoutProps) => {
  const isMenuOpen = useSelector(selectMenuOpen);

  return (
    <>
      <header className={classNames(styles.header, 'container', isMenuOpen && styles.menuOpen)} id="header">
        <div className={styles.menu}>
          <SideMenu />
        </div>
        {(title || breadcrumbs) &&
          <div className={styles.nav}>
            {title && <div className={styles.title}>{title}</div>}
            {breadcrumbs &&
              <div className={styles.breadcrumbs}>
                <Breadcrumbs items={breadcrumbs} placeholder={breadcrumbsPlaceholder} onChange={onBreadCrumbsChange} />
              </div>
            }
          </div>
        }
        {titleButtons && (
          <div className={styles.buttons}>
            {titleButtons}
          </div>
        )}
      </header>

      <div className={classNames(styles.content, 'container', className, isMenuOpen && styles.menuOpen)}>
        {children}
      </div>
    </>
  );
};

export default AdminLayout;
