import { useTranslation } from 'react-i18next';
import { Navigate, useParams, Link } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import FolderEdit from 'components/FolderEdit';

import {
  useGetSharedFoldersTreeQuery,
  useSaveSharedFolderMutation,
} from 'store/templates/templatesApi';

const findInTree = (id, tree) => {
  for (const item of tree) {
    if (item.id === id) return item;
    const children = item.children && findInTree(id, item.children);
    if (children) return children;
    continue;
  }
};

const Folder = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: foldersTree } = useGetSharedFoldersTreeQuery();
  const [saveFolder, { isLoading: isSaveLoading }] = useSaveSharedFolderMutation();

  const handleSave = (values) => {
    saveFolder({ id, ...values });
  };

  const folder = foldersTree && findInTree(id, foldersTree);

  if (foldersTree && !folder) return <Navigate to="/templates" />;

  return (
    <AdminLayout
      title={t('shared_templates')}
      breadcrumbs={[
        { name: t('shared_templates'), to: '/templates', as: Link },
        { name: folder?.name },
        {
          name: t('description')
        }]}
    >
      <PageTitle>{t('site_name') + ' – ' + t('shared_templates')}</PageTitle>
      <FolderEdit
        data={folder}
        onSave={handleSave}
        isSaveLoading={isSaveLoading}
      />
    </AdminLayout>
  );
};

export default Folder;
