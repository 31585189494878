import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Modal, Button, Input } from '@forma/forma-ui-kit';

import styles from './managers-list.module.css';

const ManagersList = ({ items, selected, onAdd, onDelete }) => {
  const { t } = useTranslation();
  const [ name, setName ] = useState('');
  const [ isRemoveOpen, setRemoveOpen ] = useState(false);
  const location = useLocation();

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {t('managers')}
      </div>
      {items?.map(({ id, fullName }) => (
        <div className={styles.item} key={id}>
          <Button
            as={Link}
            viewStyle={selected === id ? 'primary' : 'secondary'}
            className={styles.button}
            size="small"
            to={`${location.pathname}?managerId=${id}`}
          >
            {fullName}

            {selected === id && (
              <ReactSVG src="/icons/close.svg" className={styles.delete} onClick={e => { e.preventDefault(); setRemoveOpen(true); }} />
            )}
          </Button>
        </div>
      ))}
      <div className={classNames(styles.item, styles.itemAdd)}>
        <Modal
          title={t('add_manager')}
          control={
            <Button
              viewStyle="primary"
              className={styles.buttonAdd}
              size="small"
            >
              {t('add_one_more')}
            </Button>
          }
          buttons={[
            {
              viewStyle: 'tertiary',
              children: t('cancel')
            },
            {
              onClick: () => { onAdd({ fullName: name }); setName(''); },
              viewStyle: 'primary',
              children: t('submit'),
              disabled: name.length < 3,
            }
          ]}
        >
          <Input
            className={styles.input}
            name="fullName"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder={t('fields.firstName')}
          />
        </Modal>

        <Modal
          open={isRemoveOpen}
          onClose={() => setRemoveOpen(false)}
          title={t('are_you_sure_delete_manager', { name: items?.find(({ id }) => id === selected)?.fullName })}
          buttons={[
            {
              viewStyle: 'tertiary',
              children: t('cancel')
            },
            {
              onClick: () => onDelete(selected),
              viewStyle: 'danger',
              children: t('delete'),
            }
          ]}
        >
        </Modal>
      </div>
    </div>
  );
};

export default ManagersList;
