import update from 'immutability-helper';
import { Checkbox, LoadingIndicator } from '@forma/forma-ui-kit';
import { useUpdateStrictFolderMutation } from 'store/variables/variablesApi';

import styles from './variable-groups.module.css';

const VariableGroups = ({ data, folders, sections }) => {
  const [ updateFolder, { isLoading: isUpdateLoading }] = useUpdateStrictFolderMutation();

  if (!data?.name) return (
    <div className={styles.root}>
      <div>Добавление групп будет доступно после создания переменной</div>
    </div>
  );

  if (!folders || !sections) return null;

  const handleToggleFolderVariable = (tattrId, checked, folder) => {
    if (checked) {
      const index = folder.attrs.findIndex(id => String(id) === String(tattrId));
      if (index !== -1) return;
      const next = update(folder, { attrs: { $push: [ String(tattrId) ] } });
      updateFolder(next);
    } else {
      const index = folder.attrs.findIndex(id => String(id) === String(tattrId));
      if (index === -1) return;
      const next = update(folder, { attrs: { $splice: [[ index, 1 ]] } });
      updateFolder(next);
    }
  };

  return (
    <div className={styles.root}>
      {isUpdateLoading && (
        <div className={styles.loader}>
          <LoadingIndicator color={'var(--color-green-primary)'} />
        </div>
      )}
      <div>
        {Object.values(sections).map(({ id, name, folders: sectionFolders }) => (
          <div className={styles.item} key={id}>
            <div className={styles.title}>{name}:</div>
            <div>{sectionFolders.map(folderId => {
              const folder = folders[folderId];
              if (!folder) return null;
              return (
                <div key={folder.id}>
                  <Checkbox
                    label={folder.name}
                    checked={folder.attrs.includes(data.id)}
                    containerClass={styles.checkbox}
                    onChange={e => handleToggleFolderVariable(data.id, e.target.checked, folder)}
                  />
                </div>
              );
            })}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VariableGroups;
