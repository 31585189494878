import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

const ControlField = forwardRef(({ component: Component, name, control, ...props }, ref) => {
  if (!Component) return null;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value = '', onChange, ...rest }, formState }) => (
        <Component
          {...props}
          {...rest}
          value={
            (props.multiple && typeof value === 'object' && Array.isArray(value))
              ? value.join(',')
              : value
          }
          onChange={e => (
            (props.multiple && typeof value === 'object' && Array.isArray(value))
              ? onChange({ target: { name: e.target.name, value: e.target.value.split(',') } })
              : onChange(e)
          )}
        />
      )}
    />
  );
});

export default ControlField;